.ImportConfigDialog-dropzone {
  background-color: #dddddd;
  border: 2px dashed #949494;
  width: 250px;
  height: 250px;
  margin: 30px auto;
  cursor: pointer;
}

.ImportConfigDialog-dropzoneText {
  color: #9c9c9c;
  width: 150px;
  margin: 0 auto;
  margin-top: 93px;
  text-align: center;
}