@import './colors';

.ProgressionatorRoot-title {
  text-align: center;
  font-size: 30px;
  margin-bottom: 30px;
}

.ProgressionatorRoot-select {
  color: $electric-blue !important;
  border-bottom: 1px solid $electric-blue;
}

.ProgressionatorRoot-select:hover:before {
  border-bottom: 2px solid $electric-blue !important;
}

.ProgressionatorRoot-select svg {
  color: $electric-blue;
}

.ProgressionatorRoot-scaleNotes {
  text-align: center;
}

.ProgressionatorRoot-chordNames {
  text-align: center;
}

.ProgressionatorRoot-scaleNotes span {
  color: $black;
  font-size: 20px;
  font-weight: bold;
}

.ProgressionatorRoot-chordContainer {
  color: $black;
  font-size: 20px;
  font-weight: bold;
  border: 2px solid $black;
  border-radius: 4px;
  cursor: pointer;
}

.ProgressionatorRoot-extendChordLabel {
  color: $black;
  font-weight: bold;
  margin-top: 10px;
}

.ProgressionatorRoot-scalePlayButton {
  cursor: pointer;
  margin: 7px auto -7px auto;
  width: 30px;
}

.ProgressionatorRoot-progressionPlayButton {
  cursor: pointer;
  width: 30px;
  margin: 0 auto;
  color: $black;
  margin-top: 15px;
  text-align: center;
  margin-bottom: -12px;
}

.ProgressionatorRoot-chordSlot {
  width: 80px;
  min-height: 40px;
  border-bottom: 2px dashed $black;
  border-top: 2px dashed $black;
  margin: 8px auto 0 auto;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding-top: 20px;
}
