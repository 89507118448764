@import './colors';

.Body-songAttributeContainer {
  min-width: 900px;
  margin: 0 auto;
}

.Body-buttonsContainer {
  min-width: 300px;
  margin: 0 auto;
  text-align: right;
}

.MuiTooltip-tooltip {
  font-size: 14px !important;
  background-color: $japanese-violet !important;
}

.Body-possibilitiesContainer {
  margin-top: 5px;
}

.Body-possibilitiesNumber {
  color: $white;
  font-style: italic;
}

.Body-possibilitiesTag {
  color: $white;
}

.Body-calcIconContainer {
  font-size: 2px;
}

.Body-SongAttributesSectionTitle {
  font-family: 'Reenie Beanie';
  font-size: 45px;
  color: $electric-blue;
  margin-bottom: 10px;
}

.Body-japaneseIndigo {
  color: $japanese-indigo;
}

.Body-white {
  color: $white;
}