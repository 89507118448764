@import './colors';

// These are flagged !important so they override the styles magically created by the React components 

.theme-font-1 {
  font-family: 'Spicy Rice', cursive !important;
}

.theme-font-2 {
  font-family: 'Reenie Beanie' !important;
}

.theme-font-mono {
  font-family: 'Cutive Mono', monospace !important;
}

.theme-color-1 {
  color: $electric-blue !important;
}

.theme-color-2 {
  color: $japanese-violet !important;
}

.theme-color-3 {
  color: $dat-yellow !important;
}

.theme-color-black {
  color: $black !important;
}

.theme-color-white {
  color: $white !important;
}

.theme-button-1 {
  color: $electric-blue !important;
  font-family: 'Reenie Beanie' !important;
  font-size: 20px !important;
  font-weight: bold !important;
}

.theme-button-2 {
  color: $japanese-violet !important;
  font-family: 'Reenie Beanie' !important;
  font-size: 20px !important;
  font-weight: bold !important;
}

.theme-button-1:hover {
  color: $japanese-violet !important;
}

.theme-button-2:hover {
  color: $electric-blue !important;
}

.theme-pop-on-hover:hover {
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.theme-content-container {
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 120px;
}

a:link, a:visited, a:focus, a:hover, a:active {
  text-decoration:none;
  color: $electric-blue;
}

a:hover {
  color: $japanese-violet;
}

a:active {
  color: $black;
  outline: none;
}

.font-size-20 {
  font-size: 20px !important;
}

hr {
  border-color: $electric-blue;
}