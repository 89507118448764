@import './colors';

.GeneratedTemplate-container {
  width: 60%;
  max-width: 750px;
  min-width: 440px;
  margin: 30px auto;
  color: $black;
  background-image: url('../img/paper.jpg');
  background-size: 100%;
  padding: 50px;
  border-radius: 5px;
  box-shadow: 0px 0px 6px 5px rgba(212,215,220,1);
}

@media print {
  .GeneratedTemplate-container {
    margin-top: 20mm;
    background-image: none;
    box-shadow: 0px 0px 0px 0px #000000;
}}

.GeneratedTemplate-attributesContainer {
  width: 100%;
}

.GeneratedTemplate-sectionTitle {
  font-family: 'Spicy Rice';
  font-size: 35px;
  color: $black;
}

.sectionTitleSmall {
  font-family: 'Spicy Rice';
  font-size: 17px;
  color: $black;
}

.GeneratedTemplate-listItem {
  font-family: 'Reenie Beanie';
  font-size: 20px;
}