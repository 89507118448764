@import './colors.scss';

.Footer-container {
  font-family: 'Roboto';
  font-size: 14px;
  padding: 12px 0;
  position: fixed;
  background-color: $black;
  left: 0;
  right: 0;
  bottom: 0;
  height: 34px;
  width: 100%;
  min-width: 1000px;
  -webkit-box-shadow: 0px -5px 28px -6px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px -5px 28px -6px rgba(0,0,0,0.75);
  box-shadow: 0px -5px 28px -6px rgba(0,0,0,0.75);
}

.Footer-buttons-container {
  padding-right: 30px;
}